import * as React from "react"
import { Col, Container, Row, Table } from "react-bootstrap"
import { graphql, Link, PageProps } from "gatsby"

import Layout from "../components/layout"

import { fixName } from "../../utils/name_utils"
import BrowseTab from "../components/browseTab"

type DataProps = {
  allFile: {
    nodes: [
      {
        name: string
      }
    ]
  }
}

type ContextProps = {
  initial: string
}

const BrowsePage: React.FC<PageProps<DataProps, ContextProps>> = ({
  data,
  pageContext,
}) => (
  <Layout
    pageTitle={`SoundNames starting with the letter ${pageContext.initial}`}
  >
    <BrowseTab activeInitial={pageContext.initial} />
    <Container>
      <Row>
        <Col>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {data.allFile.nodes
                .filter((o, i) => i % 2 === 0)
                .map(({ name }) => (
                  <tr key={name}>
                    <td>
                      <Link to={`/name/${name.toLowerCase()}`}>
                        {fixName(name)}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>{" "}
        <Col>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {data.allFile.nodes
                .filter((o, i) => i % 2 === 1)
                .map(({ name }) => (
                  <tr key={name}>
                    <td>
                      <Link to={`/name/${name.toLowerCase()}`}>
                        {fixName(name)}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default BrowsePage

export const query = graphql`
  query AllNamesWithInitial($regexName: String) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "silver" }
        name: { regex: $regexName }
      }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
      }
    }
  }
`
